
ul li.ql-indent-1 {
    margin-left: 2rem !important;
}

ul li.ql-indent-2 {
    margin-left: 4rem !important;
}

ul li.ql-indent-3 {
    margin-left: 6rem !important;
}

ul li.ql-indent-4 {
    margin-left: 8rem !important;
}

ol li.ql-indent-1 {
    margin-left: 2rem !important;
}

ol li.ql-indent-2 {
    margin-left: 4rem !important;
}

ol li.ql-indent-3 {
    margin-left: 6rem !important;
}

ol li.ql-indent-4 {
    margin-left: 8rem !important;
}