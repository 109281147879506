/*================================================================================
	PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
================================================================================*/
small.label {
	color:#A8BACC;
}

.fixed {
	position: fixed !important;
}

.card.account-detail hr{
	margin-top: 0;
}

h6 {
	font-size: 1rem !important;
	font-weight: 500 !important;
}

.dark-layout .switch-color {
	background-color: #283046 !important;
}

.switch-color {
	background-color: white !important;
}

// offcanvas fix
#offcanvasBoth:focus {
	outline: none;
}

.offcanvas-header.fixed .offcanvas-title {
	width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	cursor: default;
}

.modal-body.text-center h3 {
	margin: 2rem 0;
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 600;
    line-height: 1.4;
}

.modal-body.text-center img {
	margin: 2rem auto;
}

.offcanvas-body .text-danger {
	line-height: 1.3;
}

.breadcrumb:not(.active) a {
	color: #4D81E7;
	transition: all 0.3s ease-in-out;
}

// button groups
.row.btns {
	text-align: right;
}

.row.btns > .remove {
	text-align: left;
}

.content-header-right {
	text-align: right;
}

.header-btn.btn {
	color: #1d1d1d;
}

.header-btn.btn {
	color: #1d1d1d;
	display: inline-block;
}

.dark-layout .header-btn.btn:hover:not(.disabled):not(:disabled){
	border-color:#b8c2cc;
}

.header-btn.btn:hover:not(.disabled):not(:disabled) {
	background-color: transparent;
	border-color:#1d1d1d;
}

.header-btn.btn:active, .header-btn.btn:focus {
	background-color: transparent;
	border-color:#1d1d1d;
}

.header-btn.btn:active, .header-btn.btn:focus {
	border-color:#b8c2cc;
}

.header-btn span.button-txt {
    display: inline-block;
    margin-left: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 116px;
}

.header-btn.btn.round .feather {
	vertical-align: top !important;
}

@media (max-width: 767.98px){
	.header-btn.btn {
		padding: 0.4rem 0.6rem;
	}
}

// action dots
.icon-btn.btn-sm.hide-arrow {
    padding: 0;
    color: #A8BACC;
    transition: all 0.3s ease-in-out;
}

.icon-btn.btn-sm.hide-arrow:hover {
    color: #4D81E7;
}

// offcanvas
.modal-content .offcanvas-header {
	position: relative;
}

.dark-layout .modal-content .offcanvas-header {
	background-color: #283046;
}

.offcanvas.setting .offcanvas-body {
	padding: 0.8rem 1.4rem 10rem;
}

.offcanvas.setting .offcanvas-body .form-floating p {
	text-align: left;
	padding-top: 0.5rem;
}

.filter-group{
	margin-bottom: 2.8rem;
}

.filter-group input{
	margin-bottom: 1rem;
}

// global select dropdown fix
a.action,
button.action {
	color: #A8BACC;
    transition: all 0.3s ease-in-out;
}

a.action:hover,
button.action:hover {
	color: #4D81E7;
}

.dropdown-menu .dropdown-item:hover, 
.dropdown-menu .dropdown-item:focus {
    background: rgba(77, 129, 231, 0.12);
    color: #4D81E7;
}

.dropdown-menu .dropdown-item.danger:hover,
.dropdown-menu .dropdown-item.danger:focus {
  color: #ea5455;
  background-color: rgba(234,84,85,0.12);
}

.dropdown-menu .dropdown-item.warning:hover, 
.dropdown-menu .dropdown-item.warning:focus {
    color: #ff9f43;
    background-color: rgba(255, 159, 67, 0.12);
}

.dropdown-menu .dropdown-item.success:hover, 
.dropdown-menu .dropdown-item.success:focus {
    color: #28c76f!important;
	background-color: rgba(40,199,111,.12);
}

// datatable select dropdown fix
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
background: $dropdown-link-hover-bg;
}

.mat-option:hover:not(.mat-option-disabled) .mat-option-text,
.mat-option:focus:not(.mat-option-disabled)  .mat-option-text {
color: $dropdown-link-hover-color;
}

.mat-button.mat-button-disabled, 
.mat-icon-button.mat-button-disabled, 
.mat-stroked-button.mat-button-disabled, 
.mat-flat-button.mat-button-disabled {
color: $theme-dark-text-muted-color;
}

// paginator fix
.mat-paginator-container {
	padding: 0 !important;
}

.mat-paginator-page-size-label {
	margin-left: 0 !important;
}

.mat-select-value,
.mat-select-arrow {
	color: #1d1d1d !important;
}

.mat-form-field-flex {
	border-bottom: 1px solid #1d1d1d;
}

.dark-layout .mat-select-value,
.dark-layout .mat-select-arrow {
	color: #B4B7BD !important;
}

.dark-layout .mat-form-field-flex {
	border-bottom: 1px solid #B4B7BD;
}

// menu hover animation fix
.main-menu-content .navigation-main li a:hover > * {
	transition: transform 0.25s ease;
	transform: translateX(5px);
}

// Wizard dark style fix
.dark-layout .bs-stepper {
	background-color: #283046;
}
	
//Accordions for review list
.review-list label {
	font-weight: 450;
	font-size: calc(1.2536rem + 0.0432vw) !important;
}

.dark-layout .review-list .accordion .card {
	box-shadow: none !important;
}

.review-list .accordion:not(:last-of-type) .card .card-header {
	padding-left: 0;
	border-bottom: 1px solid #ebe9f1;
	border-radius: 0 !important;
	transition: all 0.3s ease-in-out;
}

.review-list .accordion .card .card-header:hover,
.review-list .accordion .column .column-item:hover {
	border-bottom: 1px solid #4D81E7 !important;
}

.review-list .accordion .card .card-header .custom-control-label,
.review-list .accordion .column .column-item .custom-control-label{
	transition: all 0.3s ease-in-out;
}

.review-list .accordion .card .card-header:hover .custom-control-label,
.review-list .accordion .column .column-item:hover .custom-control-label {
	color: #4D81E7 !important;
}

.dark-layout .review-list .accordion:not(:last-of-type) .card .card-header,
.dark-layout .review-list .accordion .card-body {
    border-bottom: 1px solid #3b4253;
}

.review-list .accordion .card-body {
	padding: 1rem;
	border-radius: 0 !important;
	border-bottom: 1px solid #ebe9f1;
}

.review-list .accordion .column {
	display: inline-block;
	width: 100%;
	padding-right: 1rem;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.review-list .accordion .column .column-item {
	padding: 1rem;
	transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
	.column {
		flex: 0 0 auto;
		width: 50% !important;
	}
}

@media (min-width: 992px) {
	.column {
		flex: 0 0 auto;
    	width: 33.33333% !important;
	}
}

//Accordions for unit list
.dark-layout .final-list .accordion .card {
	box-shadow: none !important;
}

.accordion > .card > .card-header {
	margin-bottom: 0;
}

.final-list .floor h4,
.final-list .floor div.badge,
.final-list button h4,
.final-list button div.badge{
	display: inline-block;
	vertical-align: middle;
}

.final-list h4 {
	font-weight: 450;
	font-size: calc(1.2536rem + 0.0432vw) !important;
	margin-bottom: 0;
}

.final-list .floor{
	padding-top: 1rem;
	padding-bottom: 1rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.final-list .card-header .btn.btn-icon {
    padding: 0.715rem 0.736rem !important;
    border-radius: 50% !important;
    width: unset !important;
	border-bottom: none;
}

.final-list .card-header button:not(.btn-icon) {
	border-bottom: 1px solid #ebe9f1;
	border-radius: 0 !important;
	transition: all 0.3s ease-in-out;
}

.dark-layout .final-list .card-header button:not(.btn-icon) {
	border-bottom: 1px solid #3b4253;
}

.final-list .card-header button:not(.btn-icon):hover,
.final-list .floor:hover
 {
	border-bottom: 1px solid #4D81E7 !important;
}

.final-list .card-header button:not(.btn-icon):hover h4,
.final-list .floor:hover h4  {
	color: #4D81E7 !important;
}

//Accordions for crash list
.crash-list .card-header button:not(.btn-icon) {
    border-bottom: 1px solid #ebe9f1;
    border-radius: 0 !important;
    transition: all 0.3s ease-in-out;
	text-align: left;
}


.crash-list .card-header button:not(.btn-icon):hover {
    border-bottom: 1px solid #4D81E7 !important;
}

.crash-list .card-header button:not(.btn-icon):hover .feather {
	color: #4D81E7 !important;
}

//Accordions for ad building view count details 
.accordion.ad-view-detail button.btn-link {
	text-align: left;
	font-weight: normal;
	text-align: left;
	padding: 8px 0 0;
	margin-bottom: 1rem;
}

.accordion.ad-view-detail .card-body {
	padding:0;
}
//Accordions for blog item
.post-item .accordion .card .card-header button {
    padding: 0rem !important;
	text-align: left;
	transition: all 0.3s ease-in-out;
	border-radius: 0;
}

.post-item .accordion .card .card-header button:hover {
	border-bottom: 1px solid #4D81E7 !important;
}

.post-item.reported .accordion .card .card-header button:hover {
	border-bottom: 1px solid #ea5455 !important; 
}

.post-item .accordion .card .card-body {
    padding: 0rem !important;
}

// Admin View - Locker Info
.dark-layout .machine-item:hover {
	background-color: #242b3d !important;
}

// Card Page - List View
.group-select,
.group-action,
.group-action > div,
.group-action > button
 {
    display: inline-block;

}

.group-action > div,
.group-action > button
 {
    display: inline-block;
    margin-right: 0.5rem;
}

#list-view-filter {
    position:absolute;
    right:0;
    margin-right: 0;
}

.list-view-item {
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-radius: 0;
    border-bottom: 1px solid #ebe9f1;
}

.dark-layout .list-view-item {
    border-color: #3b4253 
}

.list-view-item:last-child {
    border-bottom: 1px solid none;
}

.list-view-item:hover {
    transition: all .2s;
    background-color: #f6f6f9;
}

.dark-layout .list-view-item:hover {
    background-color: #242b3d;
}

.list-view-item .custom-control,
.list-view-item .list-item-info {
    display: inline-block;
    vertical-align: top;
}

.list-view-item .list-item-info {
    width: 90%;
}

.list-view-item .list-view-detail {
    margin-right: 1rem;
    width: 30%;
    display: inline-block;
}

.list-view-item .list-item-logo {
	width: 10%;
	display: inline-block;
}

.list-view-item .list-item-logo img{
	border-radius: 50%;
}

.list-view-item .list-item-action {
    position:absolute;
    top:1.5rem;
    right:1.5rem;
}

.list-view-item .list-view-detail:last-child {
    margin-right: 0;
}

.list-view-item .list-view-detail small.text-muted {
    margin-right: 0.5rem;
}

.list-view-item .list-item-name h6 {
    display: inline-block;
    width: 90%;
}

.list-view-item .list-view-detail.building-address {
	width: 50%;
}

//pulsing dot
.is-pulsing, .no-pulsing {
	position: relative;
}

.is-pulsing:before {
	content: "";
	display: block;
	position: absolute;
	left: -20px;
	top: -2px;
	background-color: #28c76f;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	opacity: 0;
	animation: pulse 1.25s linear;
	animation-iteration-count: infinite;
}

.is-pulsing:after,
.no-pulsing:after {
	content: "";
	display: block;
	position: absolute;
	left: -14px;
	top: 4px;
	border-radius: 50%;
	height: 8px;
	width: 8px;
}

.is-pulsing:after {
	background-color: #28c76f;
}

.no-pulsing:after {
	background-color: #a8bacc;
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(0.25);
		opacity: 0.5;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

//status dot
.dot-online,
.dot-offline {
	position: relative;
}

.dot-online:before,
.dot-offline:before {
	content: "";
	display: block;
	position: absolute;
	left: -14px;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
	height: 8px;
	width: 8px;
}

.dot-online {
	color: #28c76f;
}

.dot-online:before {
	background: #28c76f;
}

.dot-offline {
	color: #ea5455;
}

.dot-offline:before {
	background: #ea5455;
}


// angular tree view fix
tree-viewport {
	height: unset !important;
}
// swiper upload image
.img-fluid.empty .feather {
	width: 40px;
	height: 40px;
	margin-bottom: 2rem;
}

.img-fluid.empty,
.img-fluid.not-empty {
	position: relative;
}

.img-fluid.not-empty > img {
	width: 100%;
}


.img-fluid.empty > div {
	display: block;
	position: absolute;
	margin: auto;
	left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	cursor: pointer;
}

.img-fluid.not-empty .btn.update {
	z-index: 2;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	color: white;
	background-color: rgba(0, 0, 0, 0.4);
}

.img-fluid.not-empty .btn.update:hover {
	background-color: rgba(0, 0, 0, 0.8) !important;
}

.img-fluid.not-empty .btn.remove {
	z-index: 2;
	position: absolute;
	right: 1.5rem;
	top: 4.5rem;
	color: white;
	background-color: rgba(255, 0, 0, 0.4);
}

.img-fluid.not-empty .btn.remove:hover {
	background-color: rgba(255, 0, 0, 0.8) !important;
}

// angular tree view fix
tree-viewport {
	height: unset !important;
}

// admin board stats highlight dark layout fix
.dark-layout .stats-highlight .card-body > div {
	border-color: #3b4253 !important;
}

//lcoker passcode
.card.lock {
    flex: 1 1 auto;
	height: 100%;
}

// Section Title with Icon 
.section-title {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
}

.section-title h4 {
	margin-bottom: 0;
	margin-left: 1rem;
}

.section-title h4 span.badge {
	font-size: 1rem;
	margin-left: 0.5rem;
}

// Page Title with Badge 
h2.content-header-title {
	display: flex;
	align-items: center;
}

h2.content-header-title span.badge {
	font-size: 1rem;
	margin-left: 0.5rem;
}

@media (max-width: 575.98px) {
	h2.content-header-title span.badge {
		margin-left: 0;
	}
}

.search-w-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
        max-width: 95%;
    }
}

// Bike Locker Related Global Component Style==========================

// Session Special Status
.special-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    small {
        margin-left: 0.25rem;
    }
}

// Bike Locker Unit Blue Square
.locker-unit {
	position: relative;
	width: 42px;
	height: 42px;
	border-radius: 0.357rem !important;
	> * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		line-height: 1;
	}
}